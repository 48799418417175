import routerOptions0 from "/src/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}